
import React,{ useEffect } from 'react'
 
export default function Main() {
    let slideIndex = 0;
    useEffect(() => {
        // Update the document title using the browser API
        // document.title = `You clicked ${count} times`;
       showSlides();
      });
      function showSlides(){
        let i;
        let slides = document.getElementsByClassName("mySlides");
        // let dots = document.getElementsByClassName("dot");
        // if slides object is not visible on page then skip
        
        if(slides.length!=0){
        for (i = 0; i < slides.length; i++) {
          slides[i].style.display = "none";  
        }
        slideIndex++;
        if (slideIndex > slides.length) {slideIndex = 1}    
        // for (i = 0; i < dots.length; i++) {
        //   dots[i].className = dots[i].className.replace(" active", "");
        // }
        slides[slideIndex-1].style.display = "block";  
      }
        // dots[slideIndex-1].className += " active";
         setTimeout(showSlides, 5000); // Change image every 2 seconds
      }
    
    return (
        <div >

              <div class="mobile-margin">
{/* <img src="images/vihar-logo.png" style={{width}}></img> */}
<div class="mySlides fade">
  <a href="images/img1_sl_show.jpg">
  <img src="images/img1_sl_show.jpg" style={{width:"100%"}}/>
  </a>
  {/* <div class="text">Caption Text</div> */}
</div>

<div class="mySlides fade">
  <a href="images/img2_sl_show.jpg">
  <img src="images/img2_sl_show.jpg" style={{width:"100%"}}/>
  </a>
  {/* <div class="text">Caption Two</div> */}
</div>

<div class="mySlides fade">
<a href="images/img3_sl_show.jpg">
  <img src="images/img3_sl_show.jpg" style={{width:"100%"}}/>
  </a>
  {/* <div class="text">Caption Three</div> */}
</div>

<div class="mySlides fade">
<a href="images/img4_sl_show.jpg">
  <img src="images/img4_sl_show.jpg" style={{width:"100%"}}/>
  </a>
  {/* <div class="text">Caption Three</div> */}
</div>

<div class="mySlides fade">
<a href="images/img5_sl_show.jpg">
  <img src="images/img5_sl_show.jpg" style={{width:"100%"}}/>
  </a>
  {/* <div class="text">Caption Three</div> */}
</div>

</div>
        </div>
    )
}
