

import React,{ useEffect } from 'react'
 
export default function Location() {
    let slideIndex = 0;
   
    return (
        <div>

        <div class="mobile-margin">

<div class="">
<a href="images/location.jpg">
<img src="images/location.jpg" style={{width:"100%"}}/>
</a>
{/* <div class="text">Caption Text</div> */}
</div>

</div>
  </div>
    )
}
