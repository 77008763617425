// import logo from './logo.svg';
// import './App.css';



import { BrowserRouter as Router, Route, Link, Routes,useNavigate }
    from "react-router-dom";
 
import Main from "./pages/main"
// import ContactUs from "./pages/contactus"
import Amenities from "./pages/amenities"
import Plan from "./pages/plan"
import AboutUs from "./pages/aboutus"
import Location from "./pages/location"
import { ReactComponent as Logo } from './Logoshyam.svg';
import { ReactComponent as LogoYoutube } from './Logoyoutube.svg';
function App() {
  const navigate=useNavigate();

  function handleCustomClick(event) {
    // Handle form submission behaviour.
    // Then trigger navigation.
    console.log(event);
    event.preventDefault();
    // navigate("/");
  }
 const logosize ={
    width: 40,
    height: 40,
  
    // marginLeft: 70,
 }

  return (
    <div className="App">


<div>

<div class="hero_area">

<header class="header_section">
  <div class="header_top">
    <div class="container-fluid">
      <div class="contact_nav">
        <a href="">
          <i class="fa fa-phone" aria-hidden="true"></i>
          <span style={{marginLeft:  '5px'}}>
            Call : +91 9909004777
          </span>
        </a>
        <a href="">
          <i class="fa fa-envelope" aria-hidden="true"></i>
          <span style={{marginLeft:  '5px'}}>
            Email : syamkutir@gmail.com
          </span>
        </a>
      </div>
    </div>
  </div>
  <div class="header_bottom">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-lg custom_nav-container ">
        <a class="navbar-brand" href="/">
          <div style={logosize}>
            <Logo/>
          <span style={{marginLeft : 10, fontFamily: "MyFont"}}>
            Shyam Group
          </span>
          </div>
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class=""> </span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ">
            {/* can be use active class to show active on li */}
            <li class="nav-item"> 
              <Link class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" to="/">Home </Link>
            </li>
            <li class="nav-item">
            <Link class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" to="/Amenities">Amenities</Link>
            </li>
            <li class="nav-item">
            <Link class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" to="/Plan">Plan</Link>
            </li>
            <li class="nav-item">
            <Link class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" to="/Location">Location</Link>
            </li>
            {/* <li class="nav-item">
            <Link  class="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show" to="/AboutUs">About</Link>
            </li> */}
          </ul>
        </div>
      </nav>
    </div>
  </div>
</header>
{/* <!-- end header section --> */}
{/* <!-- Middle part --> */}
{/* <div>
                
                </div> */}
            
                
{/* <!-- end Middle Part --> */}
</div>
<div class="mobile-bg">
<Routes>
                    <Route exact  path="/" element={<Main />} />
                    {/* <Route exact  path="AboutUs" element={<AboutUs />} /> */}
                    <Route exact  path="Amenities" element={<Amenities />} />
                    <Route exact  path="Plan" element={<Plan />} />
                    <Route exact  path="Location" element={<Location />} />
                </Routes>
                </div>
{/* <!-- info section --> */}
<section class="info_section ">
<div class="container">
<h6>
GET IN TOUCH
</h6>
<div class="row">
<div class="col-lg-10 mx-auto">
  <div class="info_items">
    <div class="row">
      <div class="col-md-4">
        <a href="">
          <div class="item ">
            <div class="img-box ">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
            </div>
            <p>
              Naroda, Ahmedabad
            </p>
          </div>
        </a>
      </div>
      <div class="col-md-4">
        <a href="">
          <div class="item ">
            <div class="img-box ">
              <i class="fa fa-phone" aria-hidden="true"></i>
            </div>
            <p>
              +91 9909004777
            </p>
          </div>
        </a>
      </div>
      <div class="col-md-4">
        <a href="">
          <div class="item ">
            <div class="img-box">
              <i class="fa fa-envelope" aria-hidden="true"></i>
            </div>
            <p>
              syamkutir@gmail.com
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
</div>
</div>
<div class="social-box">
<h6>
Follow Us
</h6>
<div class="box">

<a href="https://www.youtube.com/watch?v=xGzVhyUPz9I">
<LogoYoutube/>
{/* <img src="/images/youtube-logo.png"></img> */}
  {/* <i class="fa fa-youtube-play" aria-hidden="false"></i> */}
</a>
</div>
</div>
</section>

{/* <!-- footer section --> */}
<footer class="footer_section">
<div class="container">
<p>
  &copy; <span id="displayDateYear"></span> All Rights Reserved By 
  <a href="/"> Shyam Group.</a>
</p>
</div>
</footer>
</div>
      
    </div>
  );
}

export default App;
